import Vue from 'vue'
import Vuex from 'vuex'
import { SetStylizationDetailsResponse, StylistApiRequestHelper } from '@/store/stylist/stylistApiRequestHelper'
import Notifications from '@/store/notifications/notifications'
import { AxiosResponse } from 'axios'
import stylizationPartDetails from '@/views/stylist/stylization/StylizationPartDetails.vue'

Vue.use(Vuex)

const getInitialState = (): any => ({
  dataLoading: true,
  stylizationDetails: null,
})

interface ImageMetadata {
  categoryId: number
  categoryName: string
  x1: Number
  y1: number
  x2: number
  y2: number
  score: number
  detailedCategory: string|null
}

export interface StylizationPartDetails {
  id: string
  url: string
  metadata: ImageMetadata
}

export interface StylizationDetails {
  id: string
  url: string
  parts: Array<StylizationPartDetails>
}

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setStylizationDetails(state: any, stylizationDetails: StylizationDetails) {
      state.stylizationDetails = stylizationDetails
      state.dataLoading = false
    },
    addStylizationDetails(state: any, stylizationPartDetails2: StylizationPartDetails) {
      state.stylizationDetails.parts.push(stylizationPartDetails2)
    },
    updateStylizationDetails(state: any): void {
      const requestPayload = {
        stylizationId: state.stylizationDetails.id,
        stylizationUrl: state.stylizationDetails.url,
        stylizationPartMetadata: state.stylizationDetails.parts,
      }
      StylistApiRequestHelper.setStylizationDetails(state.stylizationDetails.id, requestPayload)
        .then((response: AxiosResponse<SetStylizationDetailsResponse>) => {
          if (response.status !== 200) {
            Notifications.commit('addError', 'bład pobierania szczegółów stylizacji', { root: true })
            return
          }
          Notifications.commit('addPopupInfo', {
            name: 'Opis stylizacji zapisany',
            message: 'Stylizacja uaktualniona',
          }, { root: true })
        })
    },
  },
  actions: {
    getStylizationDetails(context: any, stylizationId: string) {
      StylistApiRequestHelper.getStylizationDetails(stylizationId)
        .then((result: AxiosResponse<StylizationDetails>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'bład pobierania szczegółów stylizacji', { root: true })
            return
          }
          context.commit('setStylizationDetails', result.data)
        })
    },

  },
})
