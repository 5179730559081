









































































import Vue from 'vue'
import StylizationDetailsStore, {StylizationPartDetails} from '@/store/stylist/stylizationDetailsStore'

export default Vue.extend({
  name: 'StylizationPartDetailsVue',
  props: { stylizationIndex: Number },
  computed: {
    stylizationPart(): StylizationPartDetails {
      const allStylizationParts = StylizationDetailsStore.state.stylizationDetails.parts
      return allStylizationParts[this.stylizationIndex]
    },
  },
})
