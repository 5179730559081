








































import Vue from 'vue'
import StylizationDetailsStore from '@/store/stylist/stylizationDetailsStore'
import StylizationPartDetails from '@/views/stylist/stylization/StylizationPartDetails.vue'

export default Vue.extend({
  name: 'StylizationDetailsVue',
  components: {
    StylizationPartDetails,
  },
  data() {
    return {
      xRatio: 1,
      yRatio: 1,
      rectangles: [],
      isFirstClick: true,
      tempRectangle: null,
      startX: 0,
      startY: 0,
    }
  },
  computed: {
    stylization() {
      return StylizationDetailsStore.state.stylizationDetails
    },
  },
  mounted() {
    StylizationDetailsStore.dispatch('getStylizationDetails', this.$route.params.stylizationId)
  },
  methods: {
    onImageLoad() {
      const image = this.$refs.stylizationimage as HTMLImageElement|null
      if (image != null) {
        this.xRatio = image.width / image.naturalWidth
        this.yRatio = image.height / image.naturalHeight
      }
    },
    getRectangleStyle(styliaztionPart: any, index: number) {
      const colors = ['#e9306c', '#fbdc2d', '#44bb9b']

      return {
        left: `${styliaztionPart.x1 * this.xRatio}px`,
        top: `${styliaztionPart.y1 * this.yRatio}px`,
        width: `${(styliaztionPart.x2 - styliaztionPart.x1) * this.xRatio}px`,
        height: `${(styliaztionPart.y2 - styliaztionPart.y1) * this.yRatio}px`,
        border: `2px solid ${colors[index % 3]}`,
      }
    },
    getRectangle2Style(rectangle: any) {
      console.log('rectangle')
      console.log(rectangle)
      return {
        left: `${rectangle.left}px`,
        top: `${rectangle.top}px`,
        width: `${rectangle.width}px`,
        height: `${rectangle.height}px`,
        border: '2px solid green',
      }
    },
    addStylizationPart() {
      const partNumber = StylizationDetailsStore.state.stylizationDetails.parts.length + 1
      StylizationDetailsStore.commit('addStylizationDetails', {
        id: `Unknown${partNumber}`,
        url: `${partNumber} notFound`,
        metadata: {
          categoryId: 7,
          categoryName: 'Top',
          x1: 300,
          y1: 400,
          x2: 500,
          y2: 600,
          score: 1,
        },
      })
    },
    storeAllStylization() {
      StylizationDetailsStore.commit('updateStylizationDetails')

      console.log(JSON.stringify(StylizationDetailsStore.state.stylizationDetails))
    },
  },
})
