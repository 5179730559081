import { AxiosResponse } from 'axios'
import apiRequest from '@/apiRequest'
import StylistApiRequestsPaths from '@/store/stylist/stylistApiRequestsPaths'
import ApiRequestPaths from '@/apiRequestPaths'
import { StylizationDetails } from '@/store/stylist/stylizationDetailsStore'

export interface ReportTimeFrame {
  start: string;
  end: string;
}

export interface CountForData {
  count: number
  data: string
}

export interface GeneralPerformanceData {
  periodStart: string
  views: number,
  clicks: number,
  ctr: number,
  salesCount: number,
  salesUnitsSold: number,
  salesTotal: number,
  salesConversion: number
}

export interface SetStylizationDetailsResponse {
  updated: boolean
}

export class StylistApiRequestHelper {
  static getSearchesGeneralPerformancePerDay(timeFrame: ReportTimeFrame): Promise<AxiosResponse<Array<GeneralPerformanceData>>> {
    return apiRequest.post<Array<GeneralPerformanceData>>(StylistApiRequestsPaths.GET_SEARCHES_GENERAL_PERFORMANCE_PER_DAY, timeFrame)
  }

  static async getStylizationDetails(stylizationId: string): Promise<AxiosResponse<StylizationDetails>> {
    const stylizationDetailsEndpoint = ApiRequestPaths.GET_STYLIZATION_DETAILS.replace('{stylizationId}', stylizationId)
    return apiRequest.get<StylizationDetails>(stylizationDetailsEndpoint)
  }

  static async setStylizationDetails(stylizationId: string, stylizationDetails: any): Promise<AxiosResponse<SetStylizationDetailsResponse>> {
    const stylizationUpdateEndpoint = ApiRequestPaths.UPDATE_STYLIZATION_DETAILS.replace('{stylizationId}', stylizationId)
    return apiRequest.post<SetStylizationDetailsResponse>(stylizationUpdateEndpoint, stylizationDetails)
  }
}
